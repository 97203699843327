import React, { useState, useEffect } from 'react';

  import './LaunchDetailsPage.css';
  import { Link } from 'react-router-dom';



  function LaunchDetailsPage() {


    return (
        <div className="homepage">
        <div className="launch-details-page">
        <h1>Degen Galaxy Rider Data</h1>
        <p>Select a Game ID to view detailed game data.</p>
        
        <div className="input-container">
        <Link to="/Degen Galaxy Rider"><button className='TB' >
                        Back to Game
                    </button></Link>
           
        </div>

        <div className="table-responsive">
           
                <table className="launch-details-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Adresse</th>
                            <th>GAINS</th>
                            <th>Draw Number</th>
                        </tr>
                    </thead>
                    <tbody>
                       
                    </tbody>
                </table>
          
                <p>Select a Game ID to view detailed game data.</p>
          
        </div>
    </div>
    </div>
    

      );
    }
export default LaunchDetailsPage;