import React, { useState, useEffect } from 'react';

import './LotteryPageG3.css';
import { Link } from 'react-router-dom';

import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { CONTRACT_ABI, CONTRACT_ADDRESS } from './constants';


function LotteryPage() {
  const [account, setAccount] = useState(null);
  const [provider, setProvider] = useState(null);
  const [ticketCount, setTicketCount] = useState(1); // État pour le nombre de tickets

  const connectWallet = async () => {
      if (!account) {
          try {
              const web3Modal = new Web3Modal();
              const instance = await web3Modal.connect();
              const web3Provider = new ethers.BrowserProvider(instance);

              const signer = await web3Provider.getSigner();
              const userAddress = await signer.getAddress();

              setProvider(web3Provider);
              setAccount(userAddress);
          } catch (error) {
              console.error("Failed to connect wallet:", error);
          }
      } else {
          setProvider(null);
          setAccount(null);
      }
  };

  const enterLottery = async () => {
    if (!provider) {
        console.error("Please connect your wallet first.");
        return;
    }

    try {
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);
        const tx = await contract.enter(ticketCount, { value: ethers.parseUnits((ticketCount * 2.5).toString(), "ether") });
        await tx.wait();
        console.log("Transaction successful:", tx);
    } catch (error) {
        console.error("Failed to enter the lottery:", error);
    }
};

  const increaseTicketCount = () => {
      setTicketCount(ticketCount + 1);
  };

  const decreaseTicketCount = () => {
      if (ticketCount > 1) {
          setTicketCount(ticketCount - 1);
      }
  };
return (
    <div className="homepage">
        
        <div className='TOPOFTOP'>
            <h2>DGR</h2>
        </div>
        <div className='TOPP'>
            <div className='TOPPG'>
                <div className='TOPPG1'>

 
 
    
   
            <div>Be patient</div>
      <div className="subtitle">‎ </div>
      
<p className='LatD'>‎ </p>
<div className="status-indicator">
            <span ></span>
            <span ></span>
            
        </div>


</div>

    
                </div>
                <div className='TOPPD'>
                <div className='TOPPD1'>
               
                <button className='TB' onClick={connectWallet}>
                {account ? `Déconnecter: ${account.substring(0, 6)}...${account.substring(account.length - 4)}` : 'Wallet Connect'}
            </button>
                
            </div>

            <div className='TOPPD2'>
            
            <div className="input-container">
                        <button className='TB TB-plus-minus' onClick={decreaseTicketCount}>-</button>
                        <span className='calc'>{ticketCount}</span>
                        <button className='TB TB-plus-minus' onClick={increaseTicketCount}>+</button>
                    </div>
                    <div className="input-container">
                        <span className='calc'>for {ticketCount * 2.5} MATIC</span>
                    </div>
            <button 
              className="TB2" onClick={enterLottery}>
                Enter
            </button>

            <p className='Tphrase'></p>
</div>
<div className='TOPPD2'>

<div>
    <p>My Seats:</p>
</div>


                    </div>
                    <div className='Linkbox'>
                    <button className='TB TB-connected'>Previous Draw</button>
            <button className='TB TB-connected'>The Rules</button>
            </div>
            
                </div>
            </div>
            <div className="rules-container">
            
      
      <div className='advertising2'><p>Smartcontract - <a href='https://polygonscan.com/address/0xB42d8BC74CC17CA04D035d488Dc1930D4849812a' target="_blank" rel="noopener noreferrer">0x000000000000000000000000000000000000 -</a></p></div>
            </div>
            
                
            <div className='advertising'> <p>Purchasing a ticket implies your acceptance of our Terms and Conditions  <Link to="/terms-and-conditions">( Click here )</Link>  of sale. Lottery games are meant for entertainment, not financial strategy. Only play with crypto you're prepared to lose and seek help if you feel you're developing a gambling problem. Above all, take care of yourself.</p>
            
            </div>
           
        </div>
        
    );
}

export default LotteryPage;