import React, { useState, useEffect } from 'react';

import './LotteryPageG3.css';
import { Link } from 'react-router-dom';


function LotteryPageG2() { 
    
   
  


return (
    <div className="homepage">
        
        <div className='TOPOFTOP'>
            <h2>Stellar Illuminati</h2>
        </div>
        <div className='TOPP'>
            <div className='TOPPG'>
                <div className='TOPPG1'>

 
 
    
   
            <div>Be patient</div>
      <div className="subtitle">‎ </div>
      
<p className='LatD'>‎ </p>
<div className="status-indicator">
            <span ></span>
            <span ></span>
            
        </div>


</div>

    
                </div>
                <div className='TOPPD'>
                <div className='TOPPD1'>
               
                    <button className='TB' >
                        Wallet Connect
                    </button>
                
            </div>

            <div className='TOPPD2'>
            
            <div className="input-container">
            
                <button className='TB TB-plus-minus' >-</button>
                <span className='calc'></span>
                
                <button className='TB TB-plus-minus' >+</button>
            </div>
            <div className="input-container">
            <span className='calc'>for MATIC</span>
            </div>
            <button 
              className="TB2">
                Enter
            </button>

            <p className='Tphrase'></p>
</div>
<div className='TOPPD2'>

<div>
    <p>My Seats:</p>
</div>


                    </div>
                    <div className='Linkbox'>
                    <button className='TB TB-connected'>Previous Draw</button>
            <button className='TB TB-connected'>The Rules</button>
            </div>
            
                </div>
            </div>
            <div className="rules-container">
            
      
      <div className='advertising2'><p>Smartcontract - <a href='https://polygonscan.com/address/0xB42d8BC74CC17CA04D035d488Dc1930D4849812a' target="_blank" rel="noopener noreferrer">0x000000000000000000000000000000000000 -</a></p></div>
            </div>
            
                
            <div className='advertising'> <p>Purchasing a ticket implies your acceptance of our Terms and Conditions  <Link to="/terms-and-conditions">( Click here )</Link>  of sale. Lottery games are meant for entertainment, not financial strategy. Only play with crypto you're prepared to lose and seek help if you feel you're developing a gambling problem. Above all, take care of yourself.</p>
            
            </div>
           
        </div>
        
    );
}

export default LotteryPageG2;