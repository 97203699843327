import React, { useContext,useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Welcome.css';
import Astronaut from '../assets/images/00.png';
import Game1Image from '../assets/images/101.png';
import Game2Image from '../assets/images/404.png';
import Game3Image from '../assets/images/303.png';
import logom from '../assets/images/logomatic.png';
import logos from '../assets/images/logosolana.png';
import logob from '../assets/images/baselogo.webp';
import logoarb from '../assets/images/arblogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faClock, faReceipt, faTrophy, faSatellite } from '@fortawesome/free-solid-svg-icons';
import ScrollReveal from 'scrollreveal';



const Welcome = () => {
  
  const [hoveredGame, setHoveredGame] = React.useState(null);

    React.useEffect(() => {
        ScrollReveal().reveal('.welcome-section', { delay: 150,});
        ScrollReveal().reveal('.game-central', { delay: 250,});        
        ScrollReveal().reveal('.play-step', { delay: 250,});
        ScrollReveal().reveal('.containerwhy', { delay: 150,});
        ScrollReveal().reveal('.social-media-section', { delay: 150,});
        ScrollReveal().reveal('.box', { delay: 175,});
        
        const handleScroll = () => {
          const element = document.querySelector('.jackpot');
          if (element && element.classList) {
              if (window.scrollY > 100) {
                  element.classList.add('grow-on-scroll');
                  element.classList.remove('shrink-on-scroll');
              } else {
                  element.classList.add('shrink-on-scroll');
                  element.classList.remove('grow-on-scroll');
              }
          }
      };

      document.addEventListener('scroll', handleScroll);

      return () => {
          document.removeEventListener('scroll', handleScroll);
      };
  }, []);
    

        
    return (
        <div className="homepage">
            
                <div className="welcome-section">
                <div className="left-section">
                                                 
                <h1 className="jackpot"> Decentralized
  Autonomous Lotteries
</h1>
                        
<h5>Discover if you have the heart of a Degen !</h5>
<div class="button-group">
<button className="call2action"><Link to="/Degen Galaxy Rider">Degen at heart!</Link></button>  
  <button className="call3action"><a href="#section-bas">I prefer stability!</a></button>
</div>
</div>                      
                    </div>
             
             <div className="social-media-section">
                
                
            </div>
            
                         
             <div className="game-central" id="section-bas">
                       
               <div className="team" >

               <div className="GCT">
                <p>To Infinity and Beyond...</p>
               <h3>Game Selection</h3>
               </div> 

               <div className='Gameselection'>  

               <Link to="/Degen Galaxy Rider"> 
    <div class="nft">
    <div class='main'>
      <img class='tokenImage' src={Game1Image} alt="NFT" />
      <h2>Up to x40</h2>
      <p class='description'>Join with just 2.5 MATIC and enjoy more than a one in three chance to win!</p>
      <p> 🛰️ 1st Prize : 100 MATIC</p>
      <div class='tokenInfo'>
        <div class="price">
          <ins>◘</ins>
          <p>2 . 5 MATIC</p>
        </div>
        <div class="duration">
          <ins>◷</ins>
          <p>108 entries</p>
        </div>
      </div>
      <hr />
      <div class='creator'>
        <div class='wrapper'>
          <img src={logom} alt="Creator" />
        </div>
        <p>Degen Galaxy rider</p>
        
      </div>
      <button className='membut'>Play</button>
    </div>
  </div>
  </Link>

  <Link to="/Stellar Illuminati">
  <div class="nft">
    <div class='main'>
      <img class='tokenImage' src={Game2Image} alt="NFT" />
      <h2>Up to x500</h2>
      <p class='description'>Join with just 2.5 MATIC and enjoy more than a one in three chance to win!</p>
      <p> 🛸 1st Prize : 1.250 MATIC </p>
      <div class='tokenInfo'>
        <div class="price">
          <ins>◘</ins>
          <p>2 . 5 MATIC</p>
        </div>
        <div class="duration">
          <ins>◷</ins>
          <p>N.A</p>
        </div>
      </div>
      <hr />
      <div class='creator'>
        <div class='wrapper'>
          <img src={logom} alt="Creator" />
        </div>
        <p>Stellar Illuminati</p>
      </div>
      <button className='membut'>Wait Pls</button>
    </div>
  </div>
  </Link>

  <Link to="/Ultra Quest">
  <div class="nft">
    <div class='main'>
      <img class='tokenImage' src={Game3Image} alt="NFT" />
      <h2>Up to x10 000</h2>
      <p class='description'>Join with just 2.5 MATIC and enjoy more than a one in three chance to win! </p>
      <p>🚀 1st Prize : 25.000 MATIC </p>
      <div class='tokenInfo'>
        <div class="price">
          <ins>◘</ins>
          <p>2 . 5 MATIC</p>
        </div>
        <div class="duration">
          <ins>◷</ins>
          <p>N.A</p>
        </div>
      </div>
      <hr />
      <div class='creator'>
        <div class='wrapper'>
          <img src={logom} alt="Creator" />
        </div>
        <p>Ultra Quest</p>        
      </div>
      <button className='membut'>Wait Pls</button>
    </div>
  </div> 
  </Link>

  </div> 
  
    <div className="GCT3">                
               <h7>Playtime Protocol: Quickstart Guide</h7>
               <p>01000111 01101111 01101111 01100100 00100000 01101100 01110101 01100011 01101011 00100000 01101101 01111001 00100000 01000111</p>
               </div> 

        </div> 
       
                <div className="how-to-play-section">
                
                    <div className="play-steps">
                    
                    <div className="play-step">
    <FontAwesomeIcon icon={faSatellite} size="2x" />
    <h4>Discover</h4>
    <p>Browse through our exciting lottery games selection.</p>
  </div>
  <div className="play-step">
    <FontAwesomeIcon icon={faWallet} size="2x" />
    <h4>Connect </h4>
    <p>Start by connecting your web3 wallet.</p>
  </div>
  <div className="play-step">
    <FontAwesomeIcon icon={faReceipt} size="2x" />
    <h4>Entry</h4>
    <p>Purchase tickets for just <span className='blik'> 2.5 $MATIC</span></p>
  </div>
  <div className="play-step">
    <FontAwesomeIcon icon={faClock} size="2x" />
    <h4>Wait</h4>
    <p>Hold tight until tickets are sold out.</p>
  </div>
  <div className="play-step">
    <FontAwesomeIcon icon={faTrophy} size="2x" />
    <h4>Victory</h4>
    <p>Winners are drawn and paid automatically !</p>
  </div>     

  </div>  
                  </div> 

                  </div> 

                  <div class="containerwhy">
        <div className='r1'>
  <div class="left-content">
  <h1>FeelG: A New Era of Fair Play</h1>
    <h2>Experience the Transparency Revolution in Lottery</h2>
    <p>Where Every Draw is a Step Towards Innovation</p>
</div>
 
  <div class="right-content">
  <div class="feature">
    <h3>Decentralized</h3>
    <p>At the heart of FeelG lies the principle of decentralization, a cornerstone that radically transforms the lottery experience. By harnessing the power of blockchain technology, we eliminate centralized points of failure, thereby reducing the risk of fraud and external interference. Our draws and prize distributions are not dependent on any central entity but are governed by immutable and transparent smart contracts. This approach ensures that each game is fair, every transaction is transparent, and every participant has an equal chance of winning, free from concerns of manipulation or human error.</p>
  </div>
  <div class="feature">
    <h3>Autonomous</h3>
    <p>Complementing our decentralized infrastructure, FeelG's smart contracts are designed to minimize human intervention. Except for the ability to pause the game for security reasons, all operations are automated and executed by the contract itself. This autonomy ensures that the gaming process remains unaltered and consistent, providing a coherent and reliable gaming experience. The ability to pause the game is an essential safety measure, ensuring that the platform's integrity is maintained under all circumstances.</p>
  </div>
  <div class="feature">
    <h3>Lottery</h3>
    <p>FeelG revolutionizes the traditional lottery system. Moving away from the industry standard of a 50% payout, we offer an impressive 80 to 95% redistribution rate. Plus, we guarantee a fresh opportunity in every game - players never encounter a scenario where the top prizes have already been claimed, ensuring a consistently thrilling and fair experience for everyone. This approach means higher jackpots, more frequent wins, and a player-first philosophy. Our streamlined system eliminates middlemen, allowing for faster, smoother ticket purchasing and winnings collection, redefining the lottery experience for players globally.</p>
  </div>   
  </div>
  </div>

  <div className='rright-content'>
  <div class="ffeature">
  <p>
  Simple, transparent, and fair - this is the essence of the FeelG. We believe transparency extends beyond the blockchain; our smartcontracts are designed and commented with clarity in mind, ensuring that even a grandmother can understand how it works.
  <br></br>
  Every draw is a demonstration of technology and trust. Here's our process; let's use Degen Galaxy rider as example:
  <br></br>  
  "Thanks to Chainlink VRF, 31 random numbers between 1 and 108 are generated. These numbers are used to determine winners among participants. For instance, if the first random number generated is 35, the 35th participant on the list is then selected as the winner of the 1st prize."
     
  </p>
</div>

    

    </div>
    
</div>


      

      <div class="bento-box">
      

  <div class="box" id="box1">
    <h2>The Galaxy Map</h2>
    <p className='p1'> At FeelG, our vision transcends the conventional - we're not just creating a lottery; we're crafting an experience without boundaries. Our journey is charted on a Galaxy Map that is more than just a roadmap. It represents our steadfast commitment to sustainable growth, community empowerment, and pioneering change. We are reinventing the lottery as a cosmos of endless opportunities, where transparency and innovation are not ideals but tangible realities.</p>
    <p className='p1'> As we navigate this exciting path, we prioritize progress without rush. Each step forward is thoughtful and timely, ensuring stability and integrity in our evolution. We believe in transforming the lottery into an experience that's limitless, not just in scope but in impact.</p>  
    <p className='p2'>Becoming the Largest Decentralized International Lottery: Without Border or Amount Limits.</p>
    <p className='p1'>Our journey begins with our Initial Coin Offering (ICO). This is more than an opportunity to invest; it's an invitation to join us at the forefront of a revolution. Participating in our ICO is straightforward – the best way to support us, like any web3 project, is by engaging with what we have to offer. When you play our games, you don't just receive FGT tokens; you become a part of a movement. Cherish your FGTs, as they are your gateway to this expansive universe we are building together.</p>
    <p className='p1'>Remember, the strongest support for any venture, be it in the web3 space or elsewhere, comes from believing in its products and services. Just like you would back a friend's or family member's project by using their offerings, supporting FeelG by participating in our games is the most direct way to contribute to our collective success.</p>
    <p className='p3'>Join us on this journey. Be a part of something larger, where every game played and every token held propels us closer to our shared vision.</p>
    <Link to="/Degen Galaxy Rider"><button className='TB TB-connected'>Join the ICO</button></Link>


   </div>

  <div class="box" id="box2">
  <h2>Foundation and Initial Launch</h2>
    
    <h3>Game Development :</h3>
    <p>We're kicking off an exciting journey with the launch of our initial games on the Polygon blockchain. Designed for a growing community, our games are set to increase in participant numbers and prize pots, making every draw more thrilling than the last.</p>
    <p>‎ </p>
    <h3>Development and Vision of FEELG Tokens (FGT):</h3>
<p>In a move to revolutionize our platform and enhance user engagement, we are introducing substantial utilities and rewards for the FEELG Tokens (FGT). Our focus is not only on rewarding our users but also on enriching their experience and involvement in our ecosystem.</p>
<p>We are committed to expanding our decentralized autonomous organization (DAO) branch. This initiative will empower our most passionate users to contribute directly to the game development process, enabling them to create games they are truly enthusiastic about.</p>
  </div>
  <div class="box" id="box3">
    
  <img id="cosmoImage" src={Astronaut} alt='cosmonot feelg'/>
  </div>
  <div class="box" id="box4">
    <h2>Multichain Horizons</h2>
    <p>FeelG is going multichain. Starting with Solana, we're planning to integrate with more Solidity-compatible blockchains. This multichain strategy ensures that the FeelG experience is accessible to a wider audience, regardless of their preferred blockchain.</p>
    <div className='box44'><img id="cosmosol" src={logos} alt='logo solana'/>
    <img id="cosmob" src={logob} alt='logo bnb'/><img id="cosmoarb" src={logoarb} alt='logo arbitrum'/></div>
  </div>
  <div class="box" id="box5">
    <h2>By degens for degens</h2>
    
  </div>
  
  



</div>
                          
         
                       

        </div>
    );
};

export default Welcome;
